@charset "utf-8";
// ================================
// CSS information
//  file name  :base.scss
//  style info :reset rebootの上書き用
// ================================

/* reset */
blockquote, dd, dl, dt, figure, h1, h2, h3, h4, h5, h6, li, ol, p, table, ul {
	margin: 0;
	padding: 0;
	font-size: 100%;
}
dl, ol, ul {
	list-style: none
}
h1, h2, h3, h4, h5, h6 {
	-webkit-font-feature-settings: "palt";
	font-feature-settings: "palt";
	font-weight: 400;
}
button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 0;
	border: none;
	background-color: transparent;
	outline: none
}
img {
    vertical-align: bottom;
    width: 100%;
    height: auto;
}
figcaption {
	margin-top: 1em;
}

/* basefontsize */
:root {
	// font-size: 62.5%;
}
/* reboot 上書き */
body {
	font-family: $font-family;
	line-height: 1.8;
	color: $body-color;
	// font-size: 1.6rem;
}
a {
	color: $link-color;
	@include transition();
	&:hover {
		color: $link-color;
		text-decoration: underline;
	}
}
p{
	// margin-bottom: 1em;
}
a[href^="tel:"] {
	color: inherit;
}