

@mixin paddingPercent($parentPxWidth, $pTop, $pRight, $pBottom, $pLeft){
    padding : floor((($pTop / $parentPxWidth) * 10000%)) / 100 floor((($pRight / $parentPxWidth) * 10000%)) / 100 floor((($pBottom / $parentPxWidth) * 10000%)) / 100 floor((($pLeft / $parentPxWidth) * 10000%)) / 100;
}
// 呼び出し
// .example{
//     @include paddingPercent(500, 20, 5, 20, 5);
// }

//vw計算
// 第一引数で基準となる画面幅のフォントサイズ、第二引数で基準となる画面幅を入力
@function get_vw($size, $viewport:375){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

// 呼び出し
// .example{
//   font-size: get_vw(19,375);
// }

// font-size rem計算
@mixin fz-rem($size:14,$font-base:14) {
  font-size: ($size / $font-base) + rem;
}

// 呼び出し
// .example{
//   @include fz-rem(44);
// }

// rem計算
@function get_rem($size:14,$font-base:14) {
  @return ($size / $font-base) + rem;
}

@function get_rem_n($size:14,$font-base:14) {
  @return ($size / $font-base * -1) + rem;
}

//transition
@mixin transition() {
  transition: all 0.25s ease;
}

// 呼び出し
// .example{
//   @include transition();
// }

//box-shadow
@mixin box-shadow() {
  box-shadow: 0px 3px 3px rgba(0,0,0,0.1);
}

// 呼び出し
// .example{
//   @include box-shadow();
// }
