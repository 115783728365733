@charset "utf-8";
// ================================
// CSS information
//  file name  :pagetop.scss
//  style info :pagetopのstyle
// ================================

/*============================
pagetop
============================*/
#page_top{
  width: 50px;
  height: 50px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: $body-color;
  opacity: 0.8;
  border-radius: 50%;
  z-index: 10;
  opacity: 0;
  @include transition();
  @media (max-width: 576px) {
    width: 40px;
    height: 40px;
    right: 10px;
    bottom: 10px;
  }
  &.active {
    opacity: 1;
  }
}
#page_top a{
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  text-decoration: none;
  @media (max-width: 576px) {
    width: 40px;
    height: 40px;
  }
}
#page_top a::before{
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  line-height: 1;
  content: '\f106';
  font-size: 25px;
  color: #fff;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  transform: translateY(-2px);
}