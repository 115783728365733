@charset "utf-8";
// ================================
// CSS information
//  file name  :top.scss
//  style info :topのstyle
// ================================

//kv
.p-kv {
	background-color: $gray-color;
	background: url(/img/kv.jpg) no-repeat center top $gray-color;
	background-size: cover;
	margin-bottom: 100px;
  @media (max-width: 991px) {
    margin-bottom: 80px;
	}
  @media (max-width: 767px) {
    margin-bottom: 48px;
  }
	&__inner {
		height: 200px;
		display: flex;
		align-items: center;
		@media (max-width: 767px) {
			height: auto;
			padding: 32px 0;
		}
	}
	&__title {
		font-size: 18px;
		line-height: 1.5;
		color: #fff;
		text-align: center;
		flex:1;
		@media (max-width: 767px) {
			font-size: 14px;
		}
	}
}