@charset "utf-8";
// ================================
// CSS information
//  file name  :search-list.scss
//  style info :検索結果一覧のstyle
// ================================
.p-search-header{
  padding: 32px 0;
  background-color: $gray-color;
  margin-bottom: 64px;
  @media (max-width: 767px) {
    padding: 24px 0;
    margin-bottom: 48px;
  }
  &__title {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 8px;
    span {
      display: inline-block;
    }
  }
  &__num {
    font-size: 24px;
    font-weight: 700;
    line-height: 0;
    margin-top: 24px;
    @media (max-width: 991px) {
      font-size: 20px;
    }
    span {
      font-size: .6em;
    }
  }
  &__list {
    display: table;
    margin-bottom: 4px;
    &--result {
      margin-bottom: 0;
    }
  }
  &__list-head{
    font-size: 11px;
    display: table-cell;
    white-space: nowrap;
    // width: 5em;
    padding-right: .2em;
  }
  &__list-disc{
    font-size: 14px;
    display: table-cell;
    b {
      display: inline-block;
      font-weight: 400;
      &::after {
        content: ", ";
        color: #989898;
        display: inline-block;
        padding: 0 .3em;
      }
      &:last-of-type::after {
        display: none;
      }
    }
  }
  &__result {
    // display: inline-flex;
    // align-items: baseline;
  }
  &__list-disc-num{
    font-size: 24px;
    font-weight: 700;
    span {
      font-size: .6em;
    }
  }
}
