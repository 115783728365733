@charset "utf-8";
// ================================
// CSS information
//  file name  :_modal.scss
//  style info :modalのstyle
// ================================

.c-modal {
  border: 0;
  &__header {
    padding: 0;
  }
  &__body {
    position: relative;
    padding: 1.5rem 0 .5rem;
  }

}
.c-modal-nav {
  border-bottom-width: 0;
  width: 100%;
  &__item {
    text-align: center;
  }
  .c-modal-nav__link{
    font-weight: 700;
    font-size: 14px;
    color: $brand-color-gray;
    @include transition();
    &:hover {
      border-color: transparent;
      color: rgba($brand-color-black,.6);
    }
    &.active {
      color: $brand-color-black;
      border-color: transparent;
      border-bottom: 1px solid $brand-color-red;
    }
  }
}
.c-modal__header .c-modal-close {
  font-size: 2rem;
  font-weight: normal;
  color: $brand-color-black;
  opacity: 1;
  padding: .5rem 1rem;
  margin: -.5rem -1rem -.5rem 0;
}
.c-modal-contents {
  &__title {
    margin-top: .5rem;
  }
  &__body {

  }
}
.c-modal-contents-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
    label {
      font-size: 14px;
      margin-bottom: 0;
    }
    input {
      position: absolute;
      margin-top: .48rem;
      margin-left: -1.25rem;
    }
}
.c-modal-search-num {
  position: absolute;
  left: 0;
  bottom: 0;
  @media (max-width: 1199px) {
    position: static;
  }
  &__head {
    font-size: 12px;
    // display: block;
  }
  &__num {
    font-size: 24px;
  }
}