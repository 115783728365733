@charset "utf-8";
// ================================
// CSS information
//  file name  :_table.scss
//  style info :テーブルのstyle
// ================================
.c-table {
  th,td {
    padding: 12px;
    @media (max-width: 991px) {
      font-size: 14px;
      padding: 8px;
    }
  }
}