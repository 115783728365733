@charset "utf-8";
// ================================
// CSS information
//  file name  :corp-header.scss
//  style info :会社詳細のheaderのstyle
// ================================

/*============================
p-corp-header
============================*/
.p-corp-header {
  padding: 48px 0;
  background-color: $gray-color;
  margin-bottom: 64px;
  @media (max-width: 767px) {
    padding: 24px 0;
    margin-bottom: 48px;
  }
  &__inner {
    display: flex;
    @media (max-width: 767px) {
      display: block;
    }
  }
  &__pict {
    width: 200px;
    height: auto;
    display: block;
    align-self: center;
    @media (max-width: 767px) {
      margin: 0 auto 16px;
    }
  }
  &__body {
    flex: 1;
    margin-left: 32px;
    @media (max-width: 767px) {
     margin-left: 0;
    }
  }
  &__title {
    font-size: 24px;
    font-family: $font-family-self;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: .2em;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  &__text {
    line-height: 1.5;
    opacity: .6;
    margin-bottom: 1em;
    font-size: 14px;
  }
  &__address {
    font-size: 14px;
  }
  &__lists {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.5em;
    @media (max-width: 991px) {
      align-items: top;
    }
    @media (max-width: 767px) {
      display: block;
      position: relative;
      padding-right: 50px;
    }
  }
  &__list {
    display: flex;
    margin-right: 16px;
    @media (max-width: 991px) {
      display: block;
    }
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
  &__list-head{
    align-self: flex-start;
    font-size: 13px;
    white-space: nowrap;
    color: #fff;
    background-color: $brand-color-gray;
    padding: .3em .5em;
    margin-right: .5em;
    line-height: 1;
    @media (max-width: 991px) {
      display: inline-block;
    }
  }
  &__list-disc{
    font-size: 13px;
    @media (max-width: 991px) {
      display: block;
    }
    b {
      display: inline-block;
      font-weight: 400;
      &::after {
        content: ", ";
        color: #989898;
        display: inline-block;
        padding: 0 .3em;
      }
      &:last-of-type::after {
        display: none;
      }
    }
  }
}