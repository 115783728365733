@charset "utf-8";
/*----------------------------------------------------
	汎用class
----------------------------------------------------*/

//表示非表示
.only-sp {
    @media screen and (min-width: 768px) {
        display: none !important;
    }
}

.only-pc {
    display: none !important;
    @media screen and (min-width: 768px) {
        display: block !important;
    }
}

//targetblank
.u-blank {
    &::after {
        content: "";
        display: inline-block;
        position: relative;
        top: 1px;
        width: 1em;
        height: 1em;
        margin-left: .2em;
    }
}

//color
.u-color-warning {
    color: $note-color;
}

.u-color-primary {
    color: $brand-color;
}

//typography
.u-text-bold {
    font-weight: 600 !important;
}

.u-text-normal {
    font-weight: 400 !important;
}

.u-text-center {
    text-align: center !important;
}

.u-text-left {
    text-align: left !important;
}

.u-text-right {
    text-align: right !important;
}

.u-mt0 {
    margin-top: 0px !important;
}

.u-mt10 {
    margin-top: 10px !important;
}

.u-mt20 {
    margin-top: get_rem(20, 14) !important;
}

.u-mt30 {
    margin-top: get_rem(30, 14) !important;
}

.u-mt40 {
    margin-top: get_rem(40, 14) !important;
}

.u-mt50 {
    margin-top: get_rem(50, 14) !important;
}

.u-mb10 {
    margin-bottom: 10px !important;
}

.u-mb20 {
    margin-bottom: get_rem(20, 14) !important;
}

.u-mb30 {
    margin-bottom: get_rem(30, 14) !important;
}

.u-mb40 {
    margin-bottom: get_rem(40, 14) !important;
}

.u-mb50 {
    margin-bottom: get_rem(50, 14) !important;
}

//position
.u-pos-r {
    position: relative;
}

.u-input-s {
    width: 200px;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.u-input-m {
    width: 380px;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.u-textarea {
    width: 380px;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}
