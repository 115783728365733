@charset "utf-8";
// ================================
// CSS information
//  file name  :footer.scss
//  style info :footerのstyle
// ================================

/*============================
footer
============================*/
.p-footer {
  color: #e6e6e6;
  background: $brand-color-black;
  padding: 56px 0 144px;
  @media (max-width: 767px) {
    text-align: center;
    padding: 56px 0 80px;
  }
  &__inner {
    display: flex;
    @media (max-width: 991px) {
      display: block;
    }
  }
  &__logo {
    width: 113px;
    @media (max-width: 991px) {
      margin: 0 auto;
      margin-bottom: 56px;
    }
  }
  &__nav {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 0 calc(88 / 977 * 100%);
    font-size: 14px;
    font-weight: 600;
    @media (max-width: 767px) {
      display: block;
      text-align: center;
      margin-top: -1em;
    }
    li {
      @media (max-width: 767px) {
        margin-top: 1em;
      }
    }
    a {
      color: $gray-color;
      text-decoration: none;
      @include transition();
      &:hover {
        color: #fff;
      }
    }
  }
  &__copy {
    text-align: right;
    margin-top: 50px;
    color: #e6e6e6;
    @media (max-width: 767px) {
      text-align: center;
    }
  }
}