@charset "utf-8";
// ================================
// CSS information
//  file name  :main.scss
//  style info :mainのstyle
// ================================

.p-main {
  margin-bottom: 160px;
  @media (max-width: 991px) {
    margin-bottom: 80px;
  }
  @media (max-width: 767px) {
    margin-bottom: 56px;
  }
}