@charset "UTF-8";

/*Fonts*/
.thin { font-weight: 100; }
.light { font-weight: 300; }
.regular { font-weight: 400; }
.medium { font-weight: 500; }
.bold { font-weight: 700; }
.extrabold { font-weight: 800; }
.black { font-weight: 900; }

.yu-min {
	font-family: "Yu Mincho Medium", "游明朝 Medium", "YuMincho", "游明朝体", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "serif";
}
.yu-gt {
	font-family: "Yu Gothic Medium", "游ゴシック Medium", "YuGothic", "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
}
.futo-gt {
    font-family: 'Noto Sans JP', sans-serif;
}
.caudex {
    font-family: 'Caudex', serif;
}
.noto-serif {
    font-family: 'Noto Serif', serif;
}
.oswald {
    font-family: 'Oswald', sans-serif;
}
.raleway {
    font-family: 'Raleway', sans-serif;
}
.poller-one {
    font-family: 'Poller One', cursive;
}

*, *:before, *:after {
    -webkit-box-sizing: border-box!important;
    box-sizing: border-box!important;
}

.tl { text-align: left;}
.tr { text-align: right;}
.tc { text-align: center;}

.fr { float: right;}

.clearblock {clear: both;}

.none { display: none !important;}
.inline { display: inline !important;}
.ib { display: inline-block !important;}
.block { display: block !important;}


.hd {
    display: block;
    font-size: 0.84em;
    color: #fff;
    background-color: #b41e28;
    padding: 0.7em 1.5em;
}

.cap {
    font-size: 0.96em;
}

.logo img {
    width: 100%;
    max-width: 130px;
}


// .container {
//     width: 100%;
//     margin: 0 auto;
//     line-height: 1.8;
//     letter-spacing: 0.5px;
//     padding-bottom: 1em;
// }
// .container p { margin-bottom: 0;}

// @media (min-width: 768px) {
//     .container { max-width: 720px /*48px*/;}
// }
// @media (min-width: 1024px) {
//     .container { max-width: 970px /*54px*/;}
// }
// @media (min-width: 1200px) {
//     .container { max-width: 1140px /*60px*/;}
// }


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  color: inherit;
}

h1, .h1 {
  font-size: 3rem;
}

h2, .h2 {
  font-size: 2.2rem;
}

h3, .h3 {
  font-size: 1.9rem;
}

h4, .h4 {
  font-size: 1.3rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.obi {
    /*color: #000000;文字色*/
    padding: 0.4em 0;/*上下の余白*/
    border-top: solid 2.5px #324059;/*上線*/
    border-bottom: solid 2.5px #324059;/*下線*/
    margin: 1.2em 0;
}

.rd { color: #b41e28;}
.bl { color: #324059;}
.gy { color: #777;}

.m0 { margin: 0;}
.mt-1 { margin-top: -1em;}
.mt-05 { margin-top: -0.5em;}
.mt-025 { margin-top: -0.25em;}
.mt0 { margin-top: 0;}
.mt05 { margin-top: 0.5em;}
.mt1 { margin-top: 1em;}
.mt15 { margin-top: 1.5em;}
.mt2 { margin-top: 2em;}
.mt25 { margin-top: 2.5em;}
.mt3 { margin-top: 3em;}

.mb-1 { margin-bottom: -1em;}
.mb-075 { margin-bottom: -0.75em;}
.mb-05 { margin-bottom: -0.5em;}
.mb0 { margin-bottom: 0;}
.mb025 { margin-bottom: 0.25em;}
.mb05 { margin-bottom: 0.5em;}
.mb075 { margin-bottom: 0.75em;}
.mb1 { margin-bottom: 1em;}
.mb15 { margin-bottom: 1.5em;}
.mb2 { margin-bottom: 2em;}
.mb25 { margin-bottom: 2.5em;}
.mb3 { margin-bottom: 3em;}

.p0 { padding: 0;}
.pt0 { padding-top: 0;}
.pt05 { padding-top: 0.5em;}
.pt075 { padding-top: 0.75em;}
.pt1 { padding-top: 1em;}
.pt15 { padding-top: 1.5em;}
.pt2 { padding-top: 2em;}
.pt25 { padding-top: 2.5em;}
.pt3 { padding-top: 3em;}

.pb0 { padding-bottom: 0;}
.pb05 { padding-bottom: 0.5em;}
.pb1 { padding-bottom: 1em;}
.pb15 { padding-bottom: 1.5em;}
.pb2 { padding-bottom: 2em;}
.pb25 { padding-bottom: 2.5em;}
.pb3 { padding-bottom: 3em;}

.ls0 { letter-spacing: 0;}
.ls05 { letter-spacing: 0.5px;}
.ls075 { letter-spacing: 0.75px;}
.ls1 { letter-spacing: 1px;}
.ls15 { letter-spacing: 1.5px;}
.ls2 { letter-spacing: 2px;}
.ls3 { letter-spacing: 3px;}
.ls4 { letter-spacing: 4px;}
.ls5 { letter-spacing: 5px;}

.lh14 { line-height: 1.4;}
.lh15 { line-height: 1.5;}
.lh16 { line-height: 1.6;}
.lh17 { line-height: 1.7;}
.lh18 { line-height: 1.8;}
.lh19 { line-height: 1.9;}
.lh20 { line-height: 2.0;}

.tx05rem { font-size: 0.5rem;}
.tx1rem { font-size: 1rem;}
.tx12rem { font-size: 1.2rem;}
.tx125rem { font-size: 1.25rem;}
.tx15rem { font-size: 1.5rem;}
.tx2rem { font-size: 2rem;}
.tx25rem { font-size: 2.5rem;}

.tx94per { font-size: 94%;}

.ind {
    padding-left: 0.8em;
    text-indent: -0.8em;
}
.ind1 {
    padding-left: 1em;
    text-indent: -1em;
}
.ind2 {
    padding-left: 1.6em;
    text-indent: -1.6em;
}

.underline {
    font-weight: bold;
    border-bottom: 1px solid;
    padding-bottom: 2px;
    letter-spacing: 1.4px;
}

.under-bl {
    background: linear-gradient(transparent 60%, #C5CEDE 60%);
    padding-bottom: 0.2em;
}
.under-sky {
    background: linear-gradient(transparent 60%, #E9EDF3 60%);
    padding-bottom: 0.3em;
}
.marker-sky { background-color: #E9EDF3;}


.icon-dia span::before, .icon-dia span::after {
    content: "\025c6" /*アイコンのユニコード「◆」*/;
    color: #324059;
    font-size: 1rem;
    vertical-align: middle;
    position: relative;
    top: -0.1em;
}
.icon-dia span::before { padding-right: 0.5rem;}
.icon-dia span::after { padding-left: 0.6rem;}


.box1 {
    display: inline-block;
    margin: 1em 0;
    position: relative;
    padding: 1em 2.2em 1.3em;
    border-top: solid 2px black;
    border-bottom: solid 2px black;
}
.box1:before, .box1:after {
    content: '';
    position: absolute;
    top: -10px;
    width: 2px;
    height: -webkit-calc(100% + 20px);
    height: calc(100% + 20px);
    background-color: black;
}
.box1:before { left: 10px;}
.box1:after { right: 10px;}
.box1 div {
    margin: 0;
    padding: 0;
}

ol.number { list-style-type: none; padding: 0; margin: 0; }
ol.number > li { counter-increment: cnt; font-weight: bold; margin-top: 1.5em; line-height: 1.5; text-align: center; }
ol.number > li:before { content: "［"counter(cnt)"］"; margin-right: 0.5em; border-bottom: 8px solid #C5CEDE; padding-bottom: 0.3em; }


.box2 {
    display: inline-block;
    margin: 1em 0;
    position: relative;
    padding: 1em 1em 1.3em 2.2em;
    border: solid 1px black;
    text-align: left;
}
.box2 div { margin-left: -1em; line-height: 1.4;}
// ul { padding: 0; margin: 0;}
// ul > li { margin-top: 0.3em; line-height: 1.4;}

.box3, .order, .attent, .prof-wp {
    display: block;
    width: 85%;
    margin: 1em auto 1.3em;
    position: relative;
    text-align: left;
    background: #E9EDF3;
}
.haikei-sky {
    display: block;
    background: #E9EDF3;
}

.box3 p, .sec {
    padding: 0 2em 1em;
	text-align: justify;
	text-justify: inter-ideograph;
}
.sec { padding-bottom: 0.3em;}

.box-title {
    border-bottom: solid 3px white;
    padding: 0.75em 1em 0.3em;
}

.sec ol:not(.sec-in) { counter-reset: section; list-style: none; padding-left: 0.25em; font-size: 92%;}
.sec-in { counter-reset: section; list-style: none; padding-left: 1.75em;}
.sec ol { margin-bottom: 0.75em;}
.sec ol:not(.sec-in) > li { line-height: 2;}
.sec ol > li:before {
    counter-increment: section;
    content : counters(section, '-') '.' /* 付番指示・番号右側の「.」を削除 */;
    margin-right: 0.75em;
    font-weight: bold;
    border-bottom: 7px solid #C5CEDE;
    padding-bottom: 0.1em;
}
ol.sec-in > li:before { border-bottom: none;}


/*---------------------
　料金表
---------------------*/

.pricetable {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    width: 85%;
    margin: 2em auto;
}
.pricename {
    border: solid 4px #C5CEDE;
    display: inline-block;
    border-radius: 1.2em;
    padding: 0 1em;
}
.pricetable h3 { margin: 0;}

.campaign img {
    width: 100%;
    margin: 1.5em 0 1em;
}

.nav-list {
    display: inline-block;
    width: 48.5%;
    padding: 0.7em;
    text-align: left;
	background: #324059;
    color: #fff;
}
.nav-list:not(:first-child) { margin-left: 3%;}
.nav-list > a {
    display: block;
    text-decoration: none;
    color: #fff;
}
.nav-list:hover {
    background: rgba(50,64,89,0.8);
    color: #fff;
}
.item {
    display: inline-block;
    vertical-align: middle;
}
.item img { padding-top: 0.9em;}


/*---------------------
　フォーム（申込み）
---------------------*/

.haikei-bl {
    display: block;
	background: #324059;
    color: #fff;
}
.haikei-bl .container { padding-top: 0; padding-bottom: 0;}

.bd {
    padding: 7px 0;
}

.order {
    display: table;
    table-layout: fixed;
    background: transparent;
    font-family: "Yu Gothic Medium", "游ゴシック Medium", "YuGothic", "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
    vertical-align: middle;
}
.order-label {
    float: left;
    width: 28%;
    font-weight: bold;
	padding: 1em 0.5em;
}
.order-input {
    display: table-cell;
    padding: 1em 0;
}
.order .tx1rem { font-size: 0.9rem;}

input[type="text"], textarea {
    font-size: 16px;
    padding: 8px;
	margin-right: 0.6em;
    margin-bottom: 0.3em;
}
.submit {
    padding: 1.5em 0 1em;
}

.marker-rd {
    background: linear-gradient(transparent 90%, #b41e28 0%);
    padding: 6px;
}

.attent {
    background: transparent;
    font-family: "Yu Gothic Medium", "游ゴシック Medium", "YuGothic", "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
	font-size: 14px;
	line-height: 1.6;
	border: 1px dotted black;
	padding: 0.8em;
}

.att-box { margin-top: 1.5em; padding: 1em 0 0.5em; background-color: #fdd !important;}
.att-box p { margin: 0; padding-bottom: 0.5em; }
.att-box a { color: blue;}
.att-box .order-label {
    float: none;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: block;
    padding: 0;
}
.att-box .order-input {
    display: block;
    text-align: center;
    padding: 0;
}

/*---------------------
　フッター
---------------------*/

.footer {
    font-family: "Yu Gothic Medium", "游ゴシック Medium", "YuGothic", "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
    font-size: 14px;
	line-height: 2;
    padding: 1.5em 0 0.5em;
}
a.footer:link {
	text-decoration: none;
	color: #fff;
}

a.footer:visited {
	color: #fff;
}

a.footer:hover {
	text-decoration:underline;
	font-weight: bold;
}

/*---------------------
　プロフィール
---------------------*/

.haikei-bg {
    display: block;
	background: #f4f3f1;
    margin-top: 1.2em;
    padding: 0.3em 0;
}

.bd-title {
    position: relative;
    display: block;
    margin-bottom: 2em;
}
.bd-title::before {
    content: '';
    position: absolute;
    display: inline-block;
    top: 2em;
    width: 3em;
    height: 4px;
    left: 50%;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #b41e28;
    border-radius: 1px;
}

.prof-wp {
    display: table;
    table-layout: fixed;
    background: transparent;
    vertical-align: middle;
    margin: 0 auto;
    padding: 1em 0;
    line-height: 1.6;
	text-align: justify;
	text-justify: inter-ideograph;
    /*font-feature-settings: "palt" 0, "pkna";*/
}
.prof-wp .left {
    float: left;
    width: 26%;
}
.prof-wp .right {
    display: table-cell;
    padding-left: 1em;
}

.prof-wp .name {
	font-size: 1.5rem;
	letter-spacing: 5px;
}
.prof-wp .roma {
	font-size: 14px;
}

.prof-pho {
    width: 100%;
	max-width: 200px;
}
.prof-cert {
    width: 100%;
	max-width: 260px;
    padding: 0 0 1em 0.7em;
    margin-right: -2em;
}

.prof-wp p, .age { font-size: 0.8em;}

.age { display: table;}
.txt { display: table-cell;}
.txt:first-of-type { width: 4.5em;}
.msg { font-size: 0.9em; font-style: italic; font-style: oblique; font-feature-settings: "palt" 0, "pkna";}


/*---------------------
　レスポンシブ
---------------------*/

@media screen and (min-width: 1200px) {
    .full-none { display: none !important;}
    .full-inline { display: inline !important;}
    .full-ib { display: inline-block !important;}
    .full-block { display: block !important;}

    .box3, .order, .attent, .pricetable, .prof-wp { width: 100%;}
}

@media screen and (max-width: 1023px) {
    .pcs-none { display: none !important;}
    .pcs-inline { display: inline !important;}
    .pcs-ib { display: inline-block !important;}
    .pcs-block { display: block !important;}

    .box3, .order, .attent, .pricetable, .prof-wp { width: 100%;}
    .box3 p, .sec { padding: 0 1.2em 1em;}
    .pb0 { padding-bottom: 0;}

    .prof-wp .right { padding-left: 1.2em;}
    .prof-pho { max-width: 180px;}
    .prof-cert {
        max-width: 230px;
        margin-right: 0;}
}

@media screen and (max-width: 767px) {
    .tbl-none { display: none !important;}
    .tbl-inline { display: inline !important;}
    .tbl-ib { display: inline-block !important;}
    .tbl-block { display: block !important;}

    body, .tx12rem {
        font-size: 18px;
        text-align: left !important;
    }

    // .container {
    //     max-width: initial;
    //     max-width: auto;
    //     padding-left: 1em;
    //     padding-right: 1em;
    //     box-sizing: border-box;
    // }

    // h1, .h1 { font-size: 2.3rem;}
    // h2, .h2 { font-size: 1.75rem;}
    // h3, .h3 { font-size: 1.5rem;}
    // h4, .h4 { font-size: 1.25rem;}
    // h5, .h5 { font-size: 1rem;}
    // h6, .h6 { font-size: 0.75rem;}

    .obi {
        padding: 0.3em 0;
        margin: 0.8em 0;
    }

    .mt1 { margin-top: 0.5em;}
    .mt15 { margin-top: 1.2em;}
    .mb05 { margin-bottom: 0.25em;}
    .tx125rem { font-size: 1.15rem;}

    .icon-dia span::before, .icon-dia span::after { font-size: 0.8rem;}
    .icon-dia span::before { padding-right: 0.2rem;}
    .icon-dia span::after { padding-left: 0.3rem;}

    .box1 { padding: 0.6em 2em 0.9em;}


    .bd-title { margin-bottom: -0.75em;}

    .order-label {
        float: none;
        width: 100%;
        display: block;
        overflow: hidden;
        padding: 0.5em 0em 0.5em 0.5em;
        background-color : #e5e5e5;
        border-top: 1px dotted gray;
        border-bottom: 1px dotted gray;
        margin-top: 0.3em;
    }
    .order-input {
        display: block;
        padding: 1.8em 0 1.5em;
    }

    input[type="text"], textarea {
        width: 80%;
        overflow: hidden;
	    padding: 6px;
        font-size: 98%;
        margin-bottom: 0.5em;
    }
    input[type="text"]#Username1, input[type="text"]#Username2 { width: 50%;}

    .tbl-up { margin-bottom: -1em;}

    .prof-wp .left {
        float: none;
        width: 100%;
        display: inline;
        overflow: hidden;
    }
    .prof-wp .right {
        display: block;
        padding-left: 0;
    }
    .prof-pho {
        float: right;
        margin-top: 0;
        padding: 0 0 1em 1em;
        max-width: 200px;
    }
    .prof-wp p, .age, .prof-wp .roma { font-size: 14px; letter-spacing: 0.8px;}
}

@media screen and (max-width: 599px){
    .tb-none { display: none !important;}
    .tb-inline { display: inline !important;}
    .tb-ib { display: inline-block !important;}
    .tb-block { display: block !important;}

    // body { font-size: 16px;}

    // h1, .h1 { font-size: 1.9rem; line-height: 1.2;}
    // h2, .h2, .tx15rem { font-size: 1.125rem;}
    // h3, .h3, .prof-wp .name { font-size: 1.3rem; margin-top: 0.6em;}
    // h4, .h4, h5, .h5, h6, .h6, .tx125rem, .tx12rem { font-size: 1rem; line-height: 1.7;}
    .tx1rem, .prof-wp .roma:not(.age) { font-size: 13px; line-height: 1.7;}

    h2.black { font-weight: 700;}

    .obi {
        padding: 0.25em 0;
        margin-top: 0.25em;
    }

    .mt15 { margin-top: 0.6em;}
    .mb05 { margin-bottom: 0;}

    .box1 { padding: 0.5em 2.5em 0.8em;}
    .box1:before { left: 7px;}
    .box1:after { right: 7px;}

    ol.number { padding-left: 1.8em; text-indent: -2em;}
    ol.number > li { line-height: 1.4;}
    ol.number > li:before { margin-right: 0.3em;}
    ol.number div.ind0 { text-indent: 0; margin-left: -1.8em;}

    .box3 p, .sec { padding: 0 1em 1em;}
    .pb0 { padding-bottom: 0;}

    .sec-in { padding-left: 1em;}
    .sec ol:not(.sec-in) { margin-bottom: 0;}
    ol.sec-in { margin-bottom: 0.5em;}
    .sec ol > li:before {
        border-bottom: 6px solid #C5CEDE;
        padding-bottom: 0;
    }
    ol.sec-in > li:before { border-bottom: none;}
    .sec-in li { line-height: 1.85;}

    .pricetable:first-of-type { display: block;}

    .order-input textarea { margin-bottom: -0.5em;}

    input[type="text"], textarea, input[type="text"]#Username1, input[type="text"]#Username2 { width: 98%;}
    input[type="image"] { width: 46%;}

    .order .tx1rem, .attent, .footer { font-size: 13px;}

    .tb-dn { margin-bottom: 1em;}

    .marker-rd { background: linear-gradient(transparent 92%, #b41e28 0%); padding: 4px;}

    .footer { line-height: 2.75;}

    .prof-pho { width: 46%;}

    .prof-wp .ind1 { padding-left: 0.5em;}
}

@media screen and (max-width: 499px) {
    .tbs-none { display: none !important;}
    .tbs-inline { display: inline !important;}
    .tbs-ib { display: inline-block !important;}
    .tbs-block { display: block !important;}

    .mb025 { margin-bottom: 0;}

    .sec ol:not(.sec-in) { padding-left: 0; font-size: 90%; letter-spacing: 0;}
    .sec-in { padding-left: 0.5em;}
    .sec ol > li:before { margin-right: 0.4em;}

    .pricetable { display: block;}

    .nav-list {
        display: block;
        margin: -1.8em auto 0;
        width: 90%;
        padding: 1em 0.5em;
        font-size: 0.96em;
    }
    .nav-list:not(:first-child) { margin: 1.2em auto 0;}
    .item img { padding-top: 0.2em;}


    .tbs-dn { margin-bottom: 1em;}

    p .h3 { line-height: 1.2;}

    input[type="image"] { width: 56%;}

    .tbs-up { margin-bottom: -0.8em;}
}

@media screen and (max-width: 375px) {
    .sp-none { display: none !important;}
    .sp-inline { display: inline !important;}
    .sp-ib { display: inline-block !important;}
    .sp-block { display: block !important;}

    body, .tx125rem, .tx12rem, .msg { font-size: 14px;}
    /*
    h5, .h5, h6, .h6, .tx125rem { font-size: 14px;}

    h1, .h1 { font-size: 1.2rem;}
    h2, .h2, h3, .h3, h4, .h4 { font-size: 0.85rem;}
    h3, .h3 { margin-bottom: 0.25em;}
    */
    h4, .h4 { margin-bottom: 0;}

    .obi { padding: 0.1em 0; margin: 0.3em 0 0.6em; border-top: solid 2px #324059; border-bottom: solid 2px #324059;}

    .underline { padding-bottom: 1px;}

    .mt15 { margin-top: 0.9em;}

    .box1 { display: block; padding: 0.5em 1.5em 0.8em 2em;}

    .order .tx1rem, .attent { font-size: 12px;}

    input[type="image"] { width: 65%;}

    .prof-wp .ind1 { padding-left: 0; text-indent: 0;}
    .msg { font-feature-settings: "palt";}
}
