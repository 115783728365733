@charset "utf-8";
// ================================
// CSS information
//  file name  :style.css
//  style info :各cssファイル読み込み用
// ================================

// googlefonts
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

//旧css読み込み

@import "_layout";

// 変数,mixinの管理
@import "vars";

//mixinの管理
@import "mixin";

// -----------------------------------------------------------------
// plugin
// -----------------------------------------------------------------

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// ==========================================================================
// Foundation
// ==========================================================================

@import "foundation/_base";

// ==========================================================================
// Layout
// ==========================================================================

@import "layout/_layout";

// ==========================================================================
// Object
// ==========================================================================

// -----------------------------------------------------------------
// Component
// -----------------------------------------------------------------

@import "object/component/_component";
@import "object/component/_pagination";
@import "object/component/_table";
@import "object/component/_modal";

// -----------------------------------------------------------------
// Project
// -----------------------------------------------------------------

@import "object/project/_header";
@import "object/project/_footer";
@import "object/project/_main";
@import "object/project/_top";
@import "object/project/_search-list";
@import "object/project/_pagetop";
@import "object/project/_corp-header";

// -----------------------------------------------------------------
// Utility
// -----------------------------------------------------------------

@import "object/utility/_utility";
