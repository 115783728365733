@charset "utf-8";
// ================================
// CSS information
//  file name  :_pagination.scss
//  style info :paginationのstyle
// ================================

.c-pagination {
  &__box {
    padding: 8px 0;
    span ,a {
      font-size: 14px;
      display: inline-block;
      color: $body-color;
      text-align: center;
      margin: 0 4px;
      min-width: 28px;
      height: 28px;
      line-height: 28px;
    }
    a {
      color: $body-color;
    }
    a {
      @include transition();
      &:hover {
        // color: $gray-color-40;
      }
    }
    .current {
      color: #ffffff;
      background-color: $brand-color-black;
      border: none;
      border-radius: 50%;
    }
    .inactive {
      border: 0;
    }
    .pre, .next {
      span {
        font-size: 11px;
        display: inline;
        @media (max-width: 991px) {
          display: none;
        }
      }
    }
  }
}
