@charset "utf-8";
/*============================
header
============================*/
.p-header {
  &__inner {
    // max-width: 1000px + $contents-gutters *2;
    // padding: 30px $contents-gutters;
    // margin: 0 auto;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: (991px)) {
      display: block;
      position: relative;
      padding: 24px 0;
    }
  }
  &__logo {
      @media (max-width: (991px)) {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
      }
    .logo__mark {
      width: 170px;
      @media (max-width: (991px)) {
        width: 120px;
      }
    }
    .logo__desc {
      line-height: 1.3;
      font-size: 12px;
      margin-top: 22px;
      @media (max-width: (991px)) {
        flex: 1;
        margin-top: 0;
        margin-left: 16px;
        padding-right: 25px;
      }
      @media (max-width: 767px) {
        font-size: 10px;
      }
    }
  }
  &__contents {
  }
  &__links {
    text-align: right;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      display: none;
    }
    a {
      color: inherit;
      font-size: 14px;
      display: inline-block;
      text-decoration: none;
      // margin-left: 24px;
      @include transition();
      &:hover {
        color: $link-color;
      }
      &:first-child {
        color: $brand-color-red;
        padding-right: 20px;
        margin-right: 19px;
        position: relative;
        &::after {
          content: "";
          width: 1px;
          height: 16px;
          background-color: $brand-color-gray;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
    }
  }
  &__search {
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      display: block;
    }
  }
  &__search-form{
    position:relative;
    width:230px;
    margin-right: 24px;
    height: 40px;
    @media (max-width: (991px)) {
      width:48%;
    }
    @media (max-width: 767px) {
      width:100%;
      margin-bottom: 8px;
    }
    .p-header__search-box{
      font-size: 14px;
      font-weight: 400;
      position:absolute;
      left:0;
      top:0;
      padding:0 1em;
      border: 0;
      outline:0;
      appearance: none;
      background:#eee;
      width: 100%;
      height:40px;
      margin: 0;
      @media (max-width: (991px)) {
        font-size: 16px;
      }
    }
  }
  &__search-form-btn{
    height:40px;
    width:40px;
    position:absolute;
    right:0;
    top:0;
    background:#eee;
    border:none;
    cursor: pointer;
  }
  &__search-form-btn-icon {
    color: $brand-color-black;
    fill: $brand-color-black;
    width: 1em;
    height: 1em;
    margin: auto;
    position:absolute;
    top:0;
    bottom: 0;
    left:0;
    right: 0;
  }
  &__search-btn {
    display: block;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    color: $body-color;
    height:40px;
    padding:0 1em;
    border: $brand-color-gray 1px solid;
    transition: all 0.25s ease;
    text-decoration: none;
    white-space: nowrap;
    @media (max-width: (991px)) {
      width:48%;
    }
    @media (max-width: 767px) {
      width:100%;
    }
    &:hover {
      color: $link-color;
      border-color: $link-color;
      text-decoration: none;
    }
  }
}
// sp
.p-sp-header {
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
}
.p-sp-header__nav {
  display: none;
  position: fixed;
  top:0;
  left: 0;
  z-index: 10;
  background-color: $brand-color-black;
  width: 100%;
  height: 100vh;
  ul{
    padding: 90px 20px;
    font-size: 14px;
    font-weight: 600;
    margin-top: -1em;
    li {
      text-align: center;
      margin-top: 1em;
    }
    a{
      color: $gray-color;
    }
  }
}
.p-sp-header__trigger {
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  bottom: auto;
  margin: 30px 0 auto auto;
  z-index: 100;
  width: 23px;
  height: 18px;
}
.p-sp-header__trigger-inner {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  z-index: 10;
  width: 23px;
  height: 18px;
  margin: auto;
  cursor: pointer;
  span{
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background: $brand-color-black;
    transition: all .4s;
    &:nth-of-type(1){
      top: 0;
    }
    &:nth-of-type(2) {
      top: 8px;
    }
    &:nth-of-type(3) {
        bottom: 0;
    }
  }
  &.active {
    span {
      background: #fff;
      &:nth-of-type(1) {
          transform:translateY(8px) rotate(-45deg);
      }
      &:nth-of-type(2) {
          opacity: 0;
      }
      &:nth-of-type(3) {
          transform:translateY(-8px) rotate(45deg);
      }
    }
  }
}
