@charset "utf-8";
// ================================
// CSS information
//  file name  :_conponent.scss
//  style info :共通style
// ================================
//btn
.c-btn-primary{
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 1;
    color: $body-color;
    padding: 16px 32px;
    display: inline-block;
    border: $brand-color-black 2px solid;
    border-radius: 25px;
    transition: all 0.25s ease;
    text-decoration: none;
    // font-family: $font-family-self;
    @include transition();
    &:hover {
      color: $link-color;
      border-color: $link-color;
      text-decoration: none;
    }
}
.c-btn-secondary{
  color: inherit;
  @include fz-rem(14,14);
  display: inline-block;
  text-align: center;
  font-weight: 400;
  line-height: 1;
  padding: 15px 35px;
  // border: 1px solid $gray-color-40;
  @include transition();
  &:hover {
    // color: $gray-color-40;
  }
}
.c-btn-tertiary {
  color: #fff;
  background-color: $brand-color;
  @include fz-rem(16,14);
  display: inline-block;
  text-align: center;
  font-weight: 400;
  line-height: 1;
  padding: get_rem(20,14) get_rem(45,14);
  cursor: pointer;
  @include transition();
  &:hover {
    color: #fff;
    // background-color: lighten($brand-color,5%);
  }
  &--black {
    background-color: $body-color;
    &:hover {
      color: #fff;
      // background-color: lighten($body-color,5%);
    }
  }
}

.c-update-list {
  border-bottom: $gray-color 1px solid;
  &:last-child {
    border-bottom: 0;
  }
	&__link {
    padding: 32px 0;
    display: block;
    color: $body-color;
    &:hover {
      color: $body-color;
      text-decoration: none;
    }
    @media (min-width: 768px) {
      display: flex;
    }
    &:hover {
      color: $body-color;
      text-decoration: none;
    }
    @at-root .c-update-list:first-child & {
      padding-top: 0;
    }
  }
  &__pict {
    width: 200px;
    margin: 0 auto;
    @media (min-width: 768px) {
      width: calc( 179 / 1110 * 100% );
      align-self: flex-start;
    }
  }
  &__content {
    flex:1;
    color: inherit;
    padding-top: 16px;
    @media (min-width: 768px) {
      padding: 0 0 0 calc( 32 / 1110 * 100% );
    }
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    @include transition();
    @media (max-width: 767px) {
      font-size: 16px;
    }
    .c-update-list__link:hover & {
      color: $link-color;
    }
  }
  &__lists {
    display: flex;
    align-items: flex-start;
    margin: 0.5em 0;
    @media (max-width: 991px) {
      align-items: top;
    }
    @media (max-width: 767px) {
      display: block;
      position: relative;
      padding-right: 50px;
    }
  }
  &__list {
    display: flex;
    margin-right: 16px;
    @media (max-width: 991px) {
      display: block;
    }
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
  &__list-head{
    align-self: flex-start;
    font-size: 13px;
    white-space: nowrap;
    color: #fff;
    background-color: $brand-color-gray;
    padding: .3em .5em;
    margin-right: .5em;
    line-height: 1;
    @media (max-width: 991px) {
      display: inline-block;
    }
  }
  &__list-disc{
    font-size: 13px;
    @media (max-width: 991px) {
      display: block;
    }
    b {
      display: inline-block;
      font-weight: 400;
      &::after {
        content: ", ";
        color: #989898;
        display: inline-block;
        padding: 0 .3em;
      }
      &:last-of-type::after {
        display: none;
      }
    }
  }
  &__etc {
    color: $brand-color-gray;
    font-size: 13px;
    span {
      margin-right: 16px;
    }
  }
  &__text {
    line-height: 1.5;
    margin-top: .5em;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}
.c-container{
  padding-left: $contents-gutters;
  padding-right: $contents-gutters;
  max-width: 1000px + $contents-gutters *2;
  overflow: hidden;
  margin: 0 auto;
}
.c-section{
  margin-bottom: 160px;
  @media (max-width: 991px) {
    margin-bottom: 80px;
  }
  @media (max-width: 767px) {
    margin-bottom: 56px;
  }
  &--border {
    padding-bottom: 80px;
    border-bottom: $brand-color-black 3px solid;
    margin-bottom: 80px;
    @media (max-width: 991px) {
      padding-bottom: 40px;
      border-bottom: $brand-color-black 2px solid;
      margin-bottom: 40px;
    }
    @media (max-width: 767px) {
      padding-bottom: 28px;
      border-bottom: $brand-color-black 2px solid;
      margin-bottom: 28px;
    }
  }
	&__title{
    color: $body-color;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.3;
    text-align: center;
    margin: 0 0 56px;
		@media (max-width: 767px) {
      font-size: 20px;
      margin: 0 0 32px;
		}
	}
	&__contents{
    margin-bottom: 64px;
	}
	&__text{
    font-size: 15px;
	}
}
.c-head {
  @include fz-rem(28);
  position: relative;
  text-align: center;
  line-height: 1;
  padding-bottom: calc(20 / 28 * 1em);
  margin-bottom: calc(60 / 28 * 1em);
  @media (max-width: 991px) {
    margin-bottom: get_rem(30);
  }
  @media (max-width: 767px) {
    @include fz-rem(20);
    margin-bottom: calc(40 / 28 * 1em);
  }
  &::after {
    content: "";
    display: block;
    background: $brand-color;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: calc(20 / 28 * 1em);
    height: calc(2 / 28 * 1em);
    margin: auto;
  }
}

.c-text {
  p {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    margin-bottom: 1em;
  }
  &__fz-md {
    font-size: 1.3em;
    @media (max-width: 767px) {
      font-size: 1.1em;
    }
  }
  @at-root %__fz-lg {
    @include fz-rem(24);
    line-height: calc(30 / 20);
    @media (max-width: 767px) {
      @include fz-rem(18);
    }
  }
  &__fz-lg {
    @extend %__fz-lg;
    &--border-b {
      @extend %__fz-lg;
      padding-bottom: .3em;
      border-bottom: 1px $brand-color solid;
    }
  }
  &__fz-xl {
    @include fz-rem(28);
    @media (max-width: 992px) {
      @include fz-rem(24);
      text-align: left;
    }
    @media (max-width: 767px) {
      @include fz-rem(18);
      text-align: left;
    }
  }
  hr {
    display: inline-block;
    width: calc(70 / 720 *100%);
    height: 1px;
    background-color: $brand-color;
    margin: get_rem(90) 0;
		@media (max-width: 767px) {
      margin: get_rem(30) 0;
		}
  }
}
.c-lists {
  & > li{
  padding-left: 1em;
  text-indent: -1em;
  }
}
.c-list {
  padding-left: 1em;
  text-indent: -1em;
}
.c-notes{
  & > li{
    padding-left: 1em;
    text-indent: -1em;
    font-size: .8em;
    opacity: .8;
  }
}
.c-note{
  padding-left: 1em;
  text-indent: -1em;
  line-height: 1.5;
  font-size: .8em;
  opacity: .8;
}
.c-article-lists {
  margin-top: -40px;
  @media (max-width: 767px) {
    margin-top: -24px;
  }
}
.c-article-list {
  margin-top: 40px;
  @media (max-width: 767px) {
    margin-top: 24px;
  }
  &__link {
    display: block;
    color: $body-color;
    &:hover {
      color: $body-color;
      text-decoration: none;
    }
    @media (min-width: 768px) {
      display: flex;
    }
  }
  &__pict {
    overflow: hidden;
    position: relative;
    width: 100%;
    @media (min-width: 768px) {
      width: calc( 160 / 484 * 100% );
      align-self: flex-start;
    }
    &::after {
      content: "";
      display: block;
      padding-top: calc(100 / 160 * 100%);
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
  }
  &__content {
    flex:1;
    padding: 10px 0;
    color: inherit;
    @media (min-width: 768px) {
      padding: 0 0 0 calc( 20 / 480 * 100% );
    }
  }
  &__date {
    display: block;
    color: $brand-color-gray;
    font-family: $font-family-self;
    font-size: 10px;
  }
  &__title {
    font-family: $font-family-self;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.3;
    margin: .3em 0;
    @include transition();
    @at-root .c-article-list__link:hover & {
      color: $link-color;
    }
  }
  &__corp {
    color: $brand-color-gray;
    font-family: $font-family-self;
    font-weight: 400;
    font-size: 10px;
  }
  &__more-btn {
    margin-top: 56px;
    @media (max-width: 767px) {
      margin-top: 32px;
    }
  }
}